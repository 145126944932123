import { Box, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { Dispatch } from 'react';
import AddClientsTooltip from '../components/add-clients-tooltip';
import { useAuthContext } from '../components/authContext';
import DateTime from '../components/dateTime';
import FooterButton from '../components/footerButton';
import { InteractionBadge } from '../components/interactionBadge';
import Layout from '../components/layout';
import LoadingWrapper from '../components/loadingWrapper';
import SelectButtonGroup from '../components/selectButtonGroup';
import { Tally, TallyTracker } from '../components/tallyTracker';
import TextInput from '../components/textInput';
import { FormActions, FormState, useNewForm } from '../forms/form';
import useAuthRouteProtection from '../hooks/useAuthRouteProtection';
import useConfig from '../hooks/useConfig';
import { Suggestions, useSuggestions } from '../hooks/useSuggestions';
import TransportIcon from '../icons/transportIcon';
import {
  ConditionExplanationText,
  goodConditionIsSelected,
} from '../services/conditions';
import { generateId } from '../services/entity';
import {
  FORM_TYPE_TITLE,
  FormConfiguration,
  FormType,
  YES_NO,
} from '../services/lists';
import { BaseProps } from '../typings/types';

export const tallyDimensions = {
  rowTitle: 'Age',
  columns: ['Male', 'Female'],
  rows: undefined,
};

interface PureNewTripProps extends FormState {
  dispatch: Dispatch<FormActions>;
  hasActiveTrips?: boolean;
  gettingConfig: boolean;
  loggingIn: boolean;
  config?: FormConfiguration;
  tally?: Tally | undefined;
  suggestions: Suggestions;
}

export const PureNewTrip: React.FC<PureNewTripProps> = ({
  name,
  caseNumber,
  timeOccurred,
  dropOffLocation,
  pickupLocation,
  otherStops,
  reasons,
  community,
  tally,
  condition,
  addIndividualClients,
  referredBy,
  repeatCustomer,
  followUp,
  followUpActions,
  followUpTime,
  submitting,
  comments,
  dispatch,
  gettingConfig,
  loggingIn,
  config,
  suggestions,
}) => {
  const isValid = dropOffLocation && dropOffLocation.length !== 0;
  const isFollowUp = followUp === 'Yes';
  const defaultConfig = config ? config : {};

  return (
    <Layout
      title={FORM_TYPE_TITLE.Transport}
      headerIcon={
        <InteractionBadge type={FormType.Transport}>
          <TransportIcon />
        </InteractionBadge>
      }
    >
      <Grid alignContent="flex-start" justify="space-between" container>
        <LoadingWrapper
          loading={gettingConfig || loggingIn}
          submitting={submitting}
          hasConfig={!!config}
          submitted={false}
        >
          <form
            autoComplete="off"
            onSubmit={(e): void => {
              e.preventDefault();
              dispatch({
                type:
                  addIndividualClients === 'Yes' ? 'ADD_NEW_CLIENTS' : 'SUBMIT',
              });
            }}
          >
            <DateTime
              label="Pick up time"
              id="pick-up-time"
              name="pick-up-time"
              value={timeOccurred}
              onChange={(timeOccurred): void => {
                dispatch({ type: 'CHANGE_TIME_OCCURRED', timeOccurred });
              }}
            />

            <SelectButtonGroup
              id="requested-by"
              label="Requested by"
              options={defaultConfig.referrers}
              suggestOther={suggestions.suggestReferredBy}
              value={referredBy}
              onChange={(referredBy): void =>
                dispatch({
                  type: 'CHANGE_REFFERED_BY',
                  referredBy: referredBy as string,
                })
              }
            />

            <SelectButtonGroup
              id="pick-up-location"
              label="Pick up location"
              options={defaultConfig.pickupLocations}
              suggestOther={suggestions.suggestLocation}
              value={pickupLocation}
              onChange={(pickupLocation): void =>
                dispatch({
                  type: 'CHANGE_PICKUP_LOCATION',
                  pickupLocation: pickupLocation as string,
                })
              }
            />

            <SelectButtonGroup
              id="other-stops"
              label="Additional stops (optional)"
              options={defaultConfig.dropoffLocations}
              suggestOther={suggestions.suggestLocation}
              value={otherStops}
              showMoreText="Add +"
              maxDisplayed={0}
              multi
              onChange={(otherStops): void =>
                dispatch({
                  type: 'CHANGE_OTHER_STOPS',
                  otherStops: otherStops as string[],
                })
              }
            />

            <SelectButtonGroup
              id="drop-off"
              label="Drop off location"
              options={defaultConfig.dropoffLocations}
              suggestOther={suggestions.suggestLocation}
              value={dropOffLocation}
              onChange={(dropOffLocation): void =>
                dispatch({
                  type: 'CHANGE_DROP_LOCATION',
                  dropOffLocation: dropOffLocation as string,
                })
              }
            />

            <SelectButtonGroup
              id="transport-reason"
              label="Transport reason"
              options={defaultConfig.reasons}
              value={reasons}
              suggestOther={suggestions.suggestReason}
              multi
              onChange={(reasons): void =>
                dispatch({
                  type: 'CHANGE_REASON',
                  reasons: reasons as string[],
                })
              }
            />
            <SelectButtonGroup
              id="community"
              label="Community of origin (optional)"
              options={defaultConfig.communities}
              suggestOther={suggestions.suggestCommunity}
              value={community}
              onChange={(community): void =>
                dispatch({
                  type: 'CHANGE_COMMUNITY',
                  community: community as string,
                })
              }
            />

            <TallyTracker
              id="age-tally"
              label="Age / Gender"
              description="Enter the number of people you've observed per gender/age group."
              tally={tally}
              dimensions={{
                ...tallyDimensions,
                rows: defaultConfig.ageGroups,
              }}
              onChange={(newTally): void => {
                dispatch({ type: 'CHANGE_TALLY', tally: newTally });
              }}
            />

            <SelectButtonGroup
              id="add-individual-clients"
              label="Adjust group client details"
              tooltip={AddClientsTooltip}
              options={YES_NO}
              value={addIndividualClients}
              onChange={(addIndividualClients): void =>
                dispatch({
                  type: 'CHANGE_ADD_INDIVIDUAL_CLIENTS',
                  addIndividualClients: addIndividualClients as string,
                })
              }
            />

            <SelectButtonGroup
              id="condition"
              label="Condition"
              options={defaultConfig.conditions}
              suggestOther={suggestions.suggestCondition}
              value={condition}
              multi
              onChange={(condition): void =>
                dispatch({
                  type: 'CHANGE_CONDITION',
                  condition: condition as string[],
                })
              }
              disableOption={goodConditionIsSelected}
            />
            <ConditionExplanationText conditions={condition} />

            <SelectButtonGroup
              id="repeat-customer"
              // A quick fix - we don't really have customers
              label="Repeat client"
              description="Have used the service before."
              options={YES_NO}
              value={repeatCustomer}
              onChange={(repeatCustomer): void =>
                dispatch({
                  type: 'CHANGE_REPEAT_CUSTOMER',
                  repeatCustomer: repeatCustomer as string,
                })
              }
            />
            <SelectButtonGroup
              id="follow-up"
              label="Follow up required?"
              options={YES_NO}
              value={followUp}
              onChange={(followUp): void =>
                dispatch({
                  type: 'CHANGE_FOLLOW_UP',
                  followUp: followUp as string,
                })
              }
            />
            {isFollowUp && (
              <SelectButtonGroup
                id="follow-up-action"
                label="Follow up action"
                options={defaultConfig.followUpActions}
                suggestOther={suggestions.suggestFollowUpAction}
                multi
                value={followUpActions}
                onChange={(followUpActions): void =>
                  dispatch({
                    type: 'CHANGE_FOLLOW_ACTION',
                    followUpActions: followUpActions as string[],
                  })
                }
              />
            )}

            {isFollowUp && (
              <SelectButtonGroup
                id="follow-up-time"
                label="Follow up time"
                options={defaultConfig.followUpTimes}
                suggestOther={suggestions.suggestFollowUpTime}
                value={followUpTime}
                onChange={(followUpTime): void =>
                  dispatch({
                    type: 'CHANGE_FOLLOW_UP_TIME',
                    followUpTime: followUpTime as string,
                  })
                }
              />
            )}

            <TextInput
              label="Case number (optional)"
              id="case-number"
              name="caseNumber"
              value={caseNumber}
              placeholder="Add number"
              type="number"
              onChange={(e): void => {
                dispatch({
                  type: 'CHANGE_CASE_NUMBER',
                  caseNumber: e.target.value,
                });
              }}
            />

            <TextInput
              label="Name (optional)"
              id="name"
              name="name"
              value={name}
              placeholder="First name"
              onChange={(e): void => {
                dispatch({ type: 'CHANGE_NAME', name: e.target.value });
              }}
            />

            <TextInput
              label="Comments (optional)"
              id="comments"
              name="comments"
              value={comments}
              multiline
              rows={3}
              onChange={(e): void => {
                dispatch({
                  type: 'CHANGE_COMMENTS',
                  comments: e.target.value,
                });
              }}
            />

            <Box>
              <FooterButton type="submit" disabled={!isValid || submitting}>
                {submitting ? (
                  <>
                    <CircularProgress />
                    <Typography variant="srOnly">SUBMITTING</Typography>
                  </>
                ) : addIndividualClients === 'Yes' ? (
                  'NEXT'
                ) : (
                  'ADD TRIP'
                )}
              </FooterButton>
            </Box>
          </form>
        </LoadingWrapper>
      </Grid>
    </Layout>
  );
};

export const NewTrip: React.FC<BaseProps> = ({ location }) => {
  const state = !!location.state ? location.state : {};
  const pathname = location.pathname;
  useAuthRouteProtection(pathname);
  const { config, gettingConfig } = useConfig();
  const formConfig = config?.items[FormType.Transport];

  const { loggingIn, getUser } = useAuthContext();
  const useNewFormResult = useNewForm({
    getUser,
    formType: FormType.Transport,
    existingId: !state?.id ? generateId() : state.id,
    config: formConfig,
  });
  const suggestions = useSuggestions(getUser);

  return (
    <PureNewTrip
      {...useNewFormResult}
      config={formConfig}
      gettingConfig={gettingConfig}
      loggingIn={loggingIn}
      suggestions={suggestions}
    />
  );
};

export default NewTrip;
